@import '@aurora/shared-client/styles/_variables.pcss';

.lia-revision {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (--lia-breakpoint-down-xs) {
    flex-flow: row wrap;
  }
}
.lia-revision-text {
  border: 1px solid var(--lia-bs-gray-300);
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: var(--lia-font-size-xxs);
  font-weight: var(--lia-font-weight-md);
  line-height: 1.09090909;
  letter-spacing: 0.03125rem;
  color: var(--lia-bs-body-color);

  @media (--lia-breakpoint-down-xs) {
    margin: 0;
  }
}

.lia-tkb-contributors-divider {
  margin: 0 6px;
  display: none;
}

.lia-publish-date + .lia-tkb-contributors-divider {
  display: block;
}
