@import '@aurora/shared-client/styles/_variables.pcss';

.lia-model {
  .lia-user-content {
    margin-left: 10px;
  }
}

.tkb-view-contributors:hover {
  text-decoration: underline;
}

.lia-body {
  @media (--lia-breakpoint-down-sm) {
    padding-bottom: 20px !important;
  }
}
