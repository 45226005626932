@import '@aurora/shared-client/styles/_variables.pcss';

.lia-message-introduction {
  font-weight: var(--lia-bs-font-weight-base);
  word-break: break-word;
  margin-bottom: 20px;

  @media (--lia-breakpoint-down-md) {
    font-size: var(--lia-bs-h5-font-size);
    line-height: var(--lia-bs-headings-line-height);
    font-weight: var(--lia-font-weight-md);
  }
}
