.lia-section-name {
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-body-color);
}

.lia-custom-field {
  font-size: var(--lia-font-size-xs);
  font-weight: var(--lia-bs-font-weight-normal);
  color: var(--lia-bs-body-color);
}

.lia-custom-fields-container {
  display: flex;
  flex-direction: column;
}
