@import '@aurora/shared-client/styles/_variables.pcss';

.lia-reply-btn {
  .lia-reply-text {
    margin-left: 5px;
  }
}
.lia-reply-divider {
  margin: 0;
  background-color: var(--lia-bs-gray-600);
}

@media (--lia-breakpoint-down-sm) {
  .lia-reply-divider {
    display: none;
  }
}
